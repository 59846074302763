* {
  font-size: 14px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiButton-label {
  font-size: 12px !important;
}

.MuiDialogContent-root:first-child {
  padding: 15px !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 50px !important;
}

.carousel-container {
  display: flex;
  height: 100px;
  background: rgba(20, 20, 20, 0.6);
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 12px;
  width: 100%;
}

.carousel-number {
  padding: 20px;
  background-color: white;
  vertical-align: middle;
  align-self: center;
  margin: 10px;
}

.menu-edit-images {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.menu-float {
  position: absolute;
  top: 80px;
  right: 10px;
  background: white;
  padding: 20px;
  width: auto;
  box-shadow: 1px 2px 10px black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 200px;
  z-index: 1;
}

.menu-float-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-float-content>.MuiFormControl-root.MuiTextField-root>.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl>.MuiOutlinedInput-input {
  padding: 4px 2px;
}

.menu-float-content>.MuiFormControlLabel-root>* {
  font-size: x-small;
}

.menu-float-title {
  font-size: smaller;
  display: flex;
  gap: 5px;
}

.menu-float-title>span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.menu-float-actions {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.menu-topbar {
  right: 0;
  top: 0;
  position: absolute;
}

.lmask {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  opacity: 0.7;

  &.fixed {
    position: fixed;
  }

  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 10px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-right: 10px solid rgba(0, 0, 0, 0);
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-radius: 100px;
    box-shadow: 0 0 70px #2187e7;
    width: 100px;
    height: 100px;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite linear;

    margin: -50px 0 0 -50px;
    position: inherit;
    top: 50%;
    left: 50%;
  }

  &:after {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 10px solid rgba(0, 183, 229, 0.9);
    opacity: .9;
    border-left: 10px solid rgba(0, 0, 0, 0);
    border-right: 10px solid rgba(0, 0, 0, 0);
    border-radius: 100px;
    box-shadow: 0 0 30px #2187e7;
    width: 60px;
    height: 60px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;

    margin: -30px 0 0 -30px;
    position: inherit;
    top: 50%;
    left: 50%;
  }
}

@-moz-keyframes spinPulse {
  0% {
    -moz-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
  }

  50% {
    -moz-transform: rotate(145deg);
    opacity: 1;
  }

  100% {
    -moz-transform: rotate(-320deg);
    opacity: 0;
  }
}

@-moz-keyframes spinoffPulse {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spinPulse {
  0% {
    -webkit-transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #2187e7;
  }

  50% {
    -webkit-transform: rotate(145deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: rotate(-320deg);
    opacity: 0;
  }
}

@keyframes spinoffPulse {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.mask-info {
  position: absolute;
  color: white;
  top: 57%;
  transform: translate3d(50%, -50%, 0);
  right: 50%;
  font-size: x-large;
  user-select: none;
  text-align: center;
}

li.selected {
  color: #242363 !important;
}

.installation-updating-box {
  background: #23b14d;
  padding: 12px;
  color: white;
  width: 90%;
}